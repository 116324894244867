import { Tooltip } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StatutType } from '../../../../components/Common/DataType'
import StatutTooltip from '../../../../components/Common/Tooltip/StatutTooltip'
import StatutCommercialisable from '../../../../assets/icons/StatutCommercialisable'
import StatutBloqueEntrpot from '../../../../assets/icons/StatutBloqueEntrepot'
import { StockDataType } from '../../../../models'
import { t } from 'i18next'
import StatutBloqueUsine from '../../../../assets/icons/StatutBloqueUsine'
import { OriginType } from '../../components/Blocage'

interface StatusStockProps {
  stock: StockDataType
  isHover?: boolean
  isMainLine?: boolean
}

function countBlockType(stock: StockDataType) {
  let blockNumber = 0
  let blockContent = []
  if (stock.blocage_entree || stock.blocage_sortie) {
    blockNumber++
    if (stock.blocage_entree && stock.blocage_sortie) {
      blockContent.push(t('statut.blockEntreSortie'))
    } else if (stock.blocage_entree) {
      blockContent.push(t('statut.blockEntre'))
    } else {
      blockContent.push(t('statut.blockSortie'))
    }
  }
  if (stock.block_sscc) {
    blockContent.push(t('statut.blockSscc'))
    blockNumber++
  }
  if (stock.block_lot) {
    blockContent.push(t('statut.blockLot'))
    blockNumber++
  }
  return { totalBlockType: blockNumber, blockContent }
}

function getPreBlockType(stock: StockDataType) {
  let blockContent = []
  const parsePreviousStatut = stock.pre_statut
    ? JSON.parse(stock.pre_statut)
    : {}
  const preBlocEntree = parsePreviousStatut.blocage_entree
  const preBlocSortie = parsePreviousStatut.blocage_sortie
  if (preBlocEntree || preBlocSortie) {
    if (preBlocEntree && preBlocSortie) {
      blockContent.push(t('statut.blockEntreSortie'))
    } else if (preBlocEntree) {
      blockContent.push(t('statut.blockEntre'))
    } else {
      blockContent.push(t('statut.blockSortie'))
    }
  }
  if (stock.pre_block_sscc) {
    blockContent.push(t('statut.blockSscc'))
  }
  if (stock.pre_block_lot) {
    blockContent.push(t('statut.blockLot'))
  }
  return blockContent?.length > 0
    ? blockContent.join(', ')
    : t('statut.available')
}

function getDisplayStatusName(
  blocage_entree: boolean | undefined,
  blocage_sortie: boolean | undefined,
  block_sscc: boolean | undefined,
  block_lot: boolean | undefined
) {
  if (blocage_entree && blocage_sortie) {
    return t('statut.blockEntreSortie')
  } else if (blocage_entree) {
    return t('statut.blockEntre')
  } else if (blocage_sortie) {
    return t('statut.blockSortie')
  } else if (block_sscc) {
    return t('statut.blockSscc')
  } else if (block_lot) {
    return t('statut.blockLot')
  } else {
    return t('statut.available')
  }
}

const StatusStock = (props: StatusStockProps) => {
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false)
  const {
    isHover,
    isMainLine,
    stock,
    stock: {
      blocage_entree,
      blocage_sortie,
      block_sscc,
      pre_statut,
      block_lot,
      block_lot_origine,
      block_sscc_origine,
    },
  } = props
  const { t } = useTranslation()

  const { totalBlockType, blockContent } = countBlockType(props.stock)

  if (totalBlockType <= 1 || isMainLine) {
    const statutIcon = () => {
      if (
        blocage_entree ||
        blocage_sortie ||
        (block_sscc && block_sscc_origine === OriginType.WAREHOUSE) ||
        (block_lot && block_lot_origine === OriginType.WAREHOUSE)
      ) {
        return StatutType.STATUT_BLOQUE_ENTREPOT
      } else if (block_lot || block_sscc) {
        return StatutType.STATUT_BLOQUE_USINE
      } else {
        return StatutType.STATUT_COMMERCIALISABLE
      }
    }

    const curStatut = () => {
      return getDisplayStatusName(
        blocage_entree,
        blocage_sortie,
        block_sscc,
        block_lot
      )
    }

    switch (statutIcon()) {
      case StatutType.STATUT_COMMERCIALISABLE:
        return (
          <Tooltip
            title={
              <StatutTooltip
                curStatut={curStatut()}
                prevStatut={getPreBlockType(stock)}
                isMainLine={isMainLine}
              />
            }
            color="#FFFFFF"
            overlayInnerStyle={{ borderRadius: 0 }}
            open={isShowTooltip}
            mouseEnterDelay={0.3}
            mouseLeaveDelay={1000}
          >
            <StatutCommercialisable
              color={isHover ? '#FFF' : '#01E37A'}
              fill={isHover ? '#8B9FC7' : 'none'}
              onMouseLeave={() => {
                setIsShowTooltip(false)
              }}
              onMouseEnter={() => {
                setIsShowTooltip(true)
              }}
            />
          </Tooltip>
        )
      case StatutType.STATUT_BLOQUE_ENTREPOT:
        return (
          <Tooltip
            title={
              <StatutTooltip
                curStatut={curStatut()}
                prevStatut={getPreBlockType(stock)}
                isMainLine={isMainLine}
              />
            }
            color="#FFFFFF"
            overlayInnerStyle={{ borderRadius: 0 }}
            open={isShowTooltip}
            mouseEnterDelay={0.3}
          >
            <StatutBloqueEntrpot
              color={isHover ? '#FFF' : '#F25524'}
              fill={isHover ? '#8B9FC7' : 'none'}
              onMouseLeave={() => {
                setIsShowTooltip(false)
              }}
              onMouseEnter={() => {
                setIsShowTooltip(true)
              }}
            />
          </Tooltip>
        )

      case StatutType.STATUT_BLOQUE_USINE:
        return (
          <Tooltip
            title={
              <StatutTooltip
                curStatut={curStatut()}
                prevStatut={getPreBlockType(stock)}
                isMainLine={isMainLine}
              />
            }
            color="#FFFFFF"
            overlayInnerStyle={{ borderRadius: 0 }}
            open={isShowTooltip}
            mouseEnterDelay={0.3}
          >
            <StatutBloqueUsine
              color={isHover ? '#FFF' : '#F25524'}
              fill={isHover ? '#8B9FC7' : 'none'}
              onMouseLeave={() => {
                setIsShowTooltip(false)
              }}
              onMouseEnter={() => {
                setIsShowTooltip(true)
              }}
            />
          </Tooltip>
        )

      default:
        return <>-</>
    }
  }

  // In case there are more than 1 block type
  // Display number of block type in line and show tooltip list block type when hover
  return (
    <Tooltip
      title={
        <StatutTooltip
          curStatut={blockContent.join(', ')}
          prevStatut={getPreBlockType(stock)}
          isMainLine={false}
        />
      }
      color="#FFFFFF"
      overlayInnerStyle={{ borderRadius: 0 }}
      open={isShowTooltip}
      mouseEnterDelay={0.3}
    >
      <div
        className="rounded-full  bg-[#0189E3] text-center mouvement-hover"
        style={{ height: 23, width: 23 }}
        onMouseLeave={() => {
          setIsShowTooltip(false)
        }}
        onMouseEnter={() => {
          setIsShowTooltip(true)
        }}
      >
        <div
          className="flex justify-center items-center"
          style={{ height: 23 }}
        >
          <div className="text-white mouvement-hover-text">
            {totalBlockType}
          </div>
        </div>
      </div>
    </Tooltip>
  )
}

export default StatusStock
