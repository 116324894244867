import { Tooltip } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Mouvement } from '../../../enum'
import '../../../features/stock/attendu/Attendu.scss'
import MovementTooltip from '../Tooltip/MovementTooltip'

interface MovementTypeProps {
  countMouvement?: (Mouvement | string | undefined)[]
  movement?: Mouvement
  countType?: (string | undefined)[]
  type?: string
  isShowTooltip?: boolean
  isChildren?: boolean
}

const MovementRenderStock = (props: MovementTypeProps) => {
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false)
  const { t } = useTranslation()
  const { movement, countMouvement, type, countType = [], isChildren } = props

  return (
    <>
      {countMouvement?.length ? (
        countMouvement.length > 1 ? (
          <div
            className="rounded-full  bg-[#0189E3] text-center mouvement-hover"
            style={{ height: 23, width: 23 }}
          >
            <div
              className="flex justify-center items-center"
              style={{ height: 23 }}
            >
              <div className="text-white mouvement-hover-text">
                {countMouvement.length}
              </div>
            </div>
          </div>
        ) : countMouvement.length === 1 && countMouvement[0] ? (
          <Tooltip
            title={
              <MovementTooltip code={countMouvement[0]} type={countType[0]} />
            }
            color="#FFFFFF"
            overlayInnerStyle={{ borderRadius: 0 }}
            open={isShowTooltip}
            mouseEnterDelay={0.3}
          >
            <div
              style={{ width: 'max-content' }}
              onMouseLeave={() => {
                setIsShowTooltip(false)
              }}
              onMouseEnter={() => {
                setIsShowTooltip(true)
              }}
            >
              <div
                className={
                  isChildren
                    ? 'bg-[#0189E333] rounded-xl px-2 font-semibold text-[#0068AC] mouvement-hover'
                    : ''
                }
              >
                {t(`mouvement.${countMouvement[0]}`)}
              </div>
            </div>
          </Tooltip>
        ) : (
          <>-</>
        )
      ) : (
        <>
          {movement ? (
            <Tooltip
              title={<MovementTooltip code={movement} type={type} />}
              color="#FFFFFF"
              overlayInnerStyle={{ borderRadius: 0 }}
              open={isShowTooltip}
              mouseEnterDelay={0.3}
            >
              <div
                style={{ width: 'max-content' }}
                onMouseLeave={() => {
                  setIsShowTooltip(false)
                }}
                onMouseEnter={() => {
                  setIsShowTooltip(true)
                }}
              >
                <div
                  className={
                    isChildren
                      ? 'bg-[#0189E333] rounded-xl px-2 font-semibold text-[#0068AC] mouvement-hover'
                      : ''
                  }
                >
                  {t(`mouvement.${movement}`)}
                </div>
              </div>
            </Tooltip>
          ) : (
            <>-</>
          )}
        </>
      )}
    </>
  )
}

export default MovementRenderStock
