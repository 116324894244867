import { t } from 'i18next'
import _, { cloneDeep } from 'lodash'
import moment from 'moment/moment'
import { EmplacementType } from '../../components/Common/DataType'
import Define from '../../constants/define'
import { NewStock, StockDataType, StockType } from '../../models'
import { randomInt } from '../random'

export const makeTableValue = (stockArray: NewStock[]) => {
  let myStockList: StockDataType[] = []
  if (stockArray) {
    stockArray.forEach((item, index) => {
      myStockList.push(makeRowParentValue(item, index))
    })
  }
  return myStockList
}

const makeRowParentValue = (stocks: NewStock, index: number) => {
  const firstChildren = stocks.stock?.[0]

  let children: StockDataType[] = []
  // only show children if there are more than one ref
  if (stocks?.stock?.length >= 1) {
    let listPalletPickingExisted: any[] = []
    cloneDeep(stocks?.stock)
      ?.sort((a, b) => {
        const createdA = a.created_at
        const createdB = b.created_at
        if (createdA < createdB) {
          return -1
        }
        if (createdA > createdB) {
          return 1
        }
        return 0
      })
      ?.forEach((e, index) => {
        const {
          sscc,
          reference_id,
          quantite,
          sous_qte,
          total_pieses,
          att_production,
          com_article,
        } = e
        const lot = att_production?.lot || com_article?.[0]?.lot
        const dluo = att_production?.dluo || com_article?.[0]?.dluo
        if (sscc) {
          const existedPalletPicking = listPalletPickingExisted.find(
            (item) =>
              item.sscc === sscc &&
              item.reference_id === reference_id &&
              item.lot === lot &&
              item.dluo === dluo
          )
          if (existedPalletPicking) {
            let currentValue = cloneDeep(
              children?.[existedPalletPicking.position]
            )
            currentValue.qte += quantite || 0
            currentValue.totalPiece =
              (currentValue.totalPiece || 0) + (total_pieses || 0)
            children?.splice(existedPalletPicking.position, 1, currentValue)
          } else {
            listPalletPickingExisted.push({
              position: children?.length,
              sscc,
              reference_id,
              quantite,
              sous_qte,
              total_pieses,
              lot,
              dluo,
            })
            children?.push(makeRowChildValue(e, stocks, index))
          }
        } else {
          children?.push(makeRowChildValue(e, stocks, index))
        }
      })
  }

  let isAllLinesHaveSameEtat = stocks.stock?.every(
    (item) => item.eta === stocks.stock[0].eta
  )
  let countEta = _.uniq(stocks.stock?.map((item) => item.eta)).length

  let isAllLinesHaveSameNiveau = stocks.stock?.every(
    (item) => item.conditionement_level === stocks.stock[0].conditionement_level
  )

  let countCdn = _.uniq(
    stocks.stock?.map((item) => item.conditionement_level)
  ).length

  //if stock have mouvement -> count in att_production, else count in stocks
  let countMouvement = _.uniq(stocks.stock?.map((item) => item?.mouvement))

  let countType = _.uniq(stocks.stock?.map((item) => item.type))
  let value: StockDataType = {
    id: stocks.id,
    key: stocks.id,
    index,
    parentId: '',
    detail: '',
    select: true,
    countMouvement,
    etat: isAllLinesHaveSameEtat ? firstChildren.eta : undefined,
    produit: firstChildren?.reference_number,
    bl: '',
    lot: '',
    listLot: _.uniq(
      children.filter((item) => !!item.lot).map((item) => item.lot)
    ),
    palette: '',
    conditionnement: isAllLinesHaveSameNiveau
      ? !firstChildren?.conditionement_level ||
        firstChildren?.conditionement_level === Define.MAX_CDN_LEVEL
        ? firstChildren?.att_production?.conditionement || ''
        : firstChildren?.conditionnement?.nom || ''
      : '',
    qte: stocks?.stock?.reduce((sum, object) => {
      return (
        sum +
        (object?.conditionement_level === Define.MAX_CDN_LEVEL
          ? object?.quantite || 0
          : 0)
      )
    }, 0),
    sousqte: children?.reduce((sum, object) => {
      return (
        sum +
        (object?.niveau === Define.MAX_CDN_LEVEL
          ? object?.sousqte || 0
          : object?.qte || 0)
      )
    }, 0),
    totalPiece: stocks?.stock?.reduce((sum, object) => {
      return sum + (object?.total_pieses || 0)
    }, 0),
    dluo: '-',
    chrono: stocks.chrono || '',
    created_by: stocks.created_by,
    modification_by: stocks.modification_by,
    update_at: stocks.update_at,
    create_at: stocks.create_at,
    countEtat: isAllLinesHaveSameEtat ? undefined : countEta,
    countCdn: isAllLinesHaveSameNiveau ? undefined : countCdn,
    niveau: isAllLinesHaveSameNiveau
      ? firstChildren?.conditionement_level || 0
      : 0,
    countType,
    blocage_entree: stocks.blocage_entree,
    blocage_sortie: stocks.blocage_sortie,
    pre_statut: stocks.pre_block_status,
    libelle: stocks.libelle_long,
    children,
  }

  return value
}

const makeRowChildValue = (
  stock: StockType,
  stocks: NewStock,
  index: number
) => {
  const parentId = stocks.id
  const siblingAmount = stocks.stock?.length
  const pre_statut = stocks?.pre_block_status
  const { blocage_entree, blocage_sortie, conditionnement, lot_reference } =
    stocks
  const { att_production, com_article, attendu, cur_volume, parent } = stock
  const isPalletePicking = !att_production
  const currentLotRef = lot_reference?.find(
    (item) => item.lot === stock.att_production?.lot
  )
  let value: StockDataType = {
    key: stock.id || randomInt(20),
    id: stock.id,
    parentId,
    siblingAmount,
    index,
    detail: '',
    select: true,
    mouvement: stock?.mouvement,
    etat: stock.eta,
    produit: stock?.reference_number || '',
    conditionnement:
      !stock?.conditionement_level ||
      stock?.conditionement_level === Define.MAX_CDN_LEVEL
        ? att_production?.conditionement || ''
        : stock?.conditionnement?.nom || '',
    qte: stock?.quantite || 0,
    sousqte: stock?.sous_qte || 0,
    totalPiece: stock?.total_pieses || 0,
    palette: att_production?.sscc || stock.sscc || '',
    dluo: att_production?.dluo
      ? moment(Number(att_production?.dluo) * 1000).format(t('date-format'))
      : com_article?.[0]?.dluo
      ? moment(Number(com_article?.[0]?.dluo) * 1000).format(t('date-format'))
      : '-',
    bl: att_production?.att_bl || attendu?.bl || parent?.attendu?.bl || '',
    commande_bl: stock.loading_bl,
    chrono: stock.att_production?.chrono || stock.chrono || '',
    sscc: stock.att_production?.sscc || stock.sscc,
    entre_time: stock.created_at
      ? moment(Number(stock.created_at) * 1000).format(t('time-format'))
      : '',
    lot: att_production?.lot || com_article?.[0]?.lot || '',
    listLot: [att_production?.lot || com_article?.[0]?.lot || ''],
    created_by: stock?.created_by || '',
    modification_by: stock?.modification_by || '',
    fabrication: isPalletePicking
      ? stock.created_at
        ? moment(Number(stock.created_at) * 1000).format(t('time-format'))
        : ''
      : stock?.fabrication_at
      ? moment(Number(stock?.fabrication_at) * 1000).format(t('time-format'))
      : '',
    liberation: stock?.liberation_at
      ? moment(Number(stock?.liberation_at) * 1000).format(t('time-format'))
      : '-',
    validation_by: stock?.validation_by || '',
    validation_at: stock.validation_at
      ? moment(Number(stock.validation_at) * 1000).format(t('time-format'))
      : '',
    reference_id: stock.reference_id,
    emplacement: EmplacementType.STANDARD,
    current_location: stock
      ? {
          allee: stock.cur_location_aisle,
          zone: stock.cur_zone_code,
          travee: stock.cur_location_bay,
          niveau:
            stock.cur_level === -1 || stock.cur_location_code?.[1] === '0'
              ? '0'
              : stock.cur_level,
          position:
            stock.cur_location_code === '00'
              ? '0'
              : stock.cur_location_code?.[0],
        }
      : undefined,
    previous_location: stock
      ? {
          allee: stock.pre_location_aisle,
          zone: stock.pre_zone_code,
          travee: stock.pre_location_bay,
          niveau:
            stock.pre_level === -1 || stock.pre_location_code?.[1] === '0'
              ? '0'
              : stock.pre_level,
          position:
            stock.pre_location_code === '00'
              ? '0'
              : stock.pre_location_code?.[0],
        }
      : undefined,
    type: stock.type,
    blocage_entree,
    blocage_sortie,
    pre_statut,
    libelle: att_production?.libelle,
    niveau: stock.conditionement_level,
    poids_qte: stock?.cur_poids_brut || 0,
    volume_qte: cur_volume,
    isPalletePicking,
    conditionnementLevel: stock.conditionement_level,
    block_sscc: stock.sscc_block || false,
    block_lot: !!currentLotRef?.lot_block && !stock.is_skip_block_lot,
    block_sscc_origine: stock?.origine,
    block_lot_origine: currentLotRef?.origine,
    status: stock.status,
    pre_block_sscc: stock.pre_sscc_block || false,
    pre_block_lot: !!currentLotRef?.pre_lot_block || false,
  }

  const preCdnLevel = Number(stock.conditionement_level) - 1
  const cdnLevel = Number(stock.conditionement_level)
  const currentCdn = conditionnement?.find(
    (item) => Number(item.niveau) === cdnLevel
  )
  if (currentCdn && currentCdn.niveau === Define.MAX_CDN_LEVEL - 1) {
    value.poids_qte = currentCdn.poids_brut
    const { hauteur, largeur, longueur } = currentCdn
    value.volume_qte = Number(hauteur) * Number(largeur) * Number(longueur)
  }
  const preCdn = conditionnement?.find(
    (item) => Number(item.niveau) === preCdnLevel
  )
  if (preCdn) {
    const { hauteur, largeur, longueur, poids_brut } = preCdn
    value.volume_sousqte = Number(hauteur) * Number(largeur) * Number(longueur)
    value.poids_sousqte = Number(poids_brut)
  }

  return value
}
