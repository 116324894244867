interface IconInterface {
  color: string
  opacity?: number
  fill?: string
  onMouseLeave: () => void
  onMouseEnter: () => void
}

export default function EtatSorti(props: IconInterface) {
  const { color, opacity, fill, onMouseEnter, onMouseLeave } = props
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 27 27"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      opacity={opacity}
      display="block"
    >
      <circle cx="13.5" cy="13.5" r="12.75" stroke={color} stroke-width="1.5" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.00014 9.24652L6 18.5218L14.0001 22.0002L21.9999 18.5218L22 9.24652L14.0001 6L6.00014 9.24652ZM17.6968 10.3447C18.0669 10.7547 18.0345 11.387 17.6246 11.7571L15.2273 13.9211L17.5518 16.1627C17.9493 16.5461 17.9608 17.1792 17.5775 17.5767C17.1941 17.9743 16.561 17.9858 16.1635 17.6024L13.7392 15.2645L11.2392 17.5213C10.8292 17.8914 10.1969 17.8591 9.82682 17.4491C9.45674 17.0392 9.48907 16.4068 9.89902 16.0367L12.2961 13.8729L9.97132 11.631C9.57377 11.2477 9.56228 10.6146 9.94565 10.2171C10.329 9.81951 10.9621 9.80802 11.3596 10.1914L13.7842 12.5295L16.2844 10.2725C16.6944 9.90241 17.3267 9.93474 17.6968 10.3447Z"
        fill={color}
      />
    </svg>
  )
}
