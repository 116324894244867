import { Tooltip } from 'antd'
import React, { useState } from 'react'

export default function ProduitStockTooltip({
  produit,
  libelle,
  isMainLine,
}: {
  libelle?: string
  produit: string
  isMainLine?: boolean
}) {
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false)

  if (!libelle) {
    return (
      <div
        className={`truncate
          ${
            isMainLine
              ? 'font-semibold text-secondary hover-text overflow-hidden whitespace-nowrap'
              : 'hover-text'
          }
        `}
        style={{ height: 23 }}
      >
        {produit}
      </div>
    )
  }

  return (
    <Tooltip
      title={<div className="m1-tooltip">{libelle}</div>}
      color="#FFFFFF"
      overlayInnerStyle={{ borderRadius: 0 }}
      open={isShowTooltip}
      mouseEnterDelay={0.3}
    >
      <div
        onMouseLeave={() => {
          setIsShowTooltip(false)
        }}
        onMouseEnter={() => {
          setIsShowTooltip(true)
        }}
        className={`truncate ${
          isMainLine ? 'font-semibold text-secondary hover-text' : 'hover-text'
        }`}
        style={{ height: 23 }}
      >
        {produit}
      </div>
    </Tooltip>
  )
}
