import { Tooltip } from 'antd'
import React, { useState } from 'react'
import { renderDigitsByNumber } from '../../../utils'
import { t } from 'i18next'
import clsx from 'clsx'

interface BLStockProps {
  attenduBl: string
  commandeBl?: string
}

const BL_NUMBER_DIGITS = 10

const BLStock = (props: BLStockProps) => {
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false)
  const { attenduBl, commandeBl } = props

  return (
    <div className="text-secondary font-semibold hover-text">
      <Tooltip
        title={
          <div className="m1-tooltip">
            {commandeBl ? (
              <div>
                <div>
                  BL {t('request')}: {attenduBl}
                </div>
                <div>
                  BL {t('order')}: {commandeBl}
                </div>
              </div>
            ) : (
              <div>{attenduBl}</div>
            )}
          </div>
        }
        color="#FFFFFF"
        overlayInnerStyle={{ borderRadius: 0 }}
        open={isShowTooltip}
        mouseEnterDelay={0.3}
      >
        <div
          onMouseLeave={() => {
            setIsShowTooltip(false)
          }}
          onMouseEnter={() => {
            if (attenduBl?.length > BL_NUMBER_DIGITS || commandeBl)
              setIsShowTooltip(true)
          }}
          className={clsx(
            commandeBl
              ? 'rounded-full bg-[#0189E3] text-center mouvement-hover w-[23px] h-[23px]'
              : 'w-max'
          )}
        >
          {commandeBl ? (
            <div className="text-white mouvement-hover-text">2</div>
          ) : (
            renderDigitsByNumber(attenduBl, BL_NUMBER_DIGITS)
          )}
        </div>
      </Tooltip>
    </div>
  )
}

export default BLStock
