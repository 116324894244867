interface IconInterface {
  color: string
  opacity?: number
  fill?: string
  onMouseLeave: () => void
  onMouseEnter: () => void
}

export default function StatutBloqueUsine(props: IconInterface) {
  const { color, opacity, fill, onMouseEnter, onMouseLeave } = props
  return (
    <svg
      data-testid="blocked-factory-status-icon"
      width="23"
      height="23"
      viewBox="0 0 27 27"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      opacity={opacity}
      display="block"
    >
      <circle cx="13.5" cy="13.5" r="12.75" stroke={color} stroke-width="1.5" />
      <path
        d="M21.2594 13.7596L15.4821 7.98237L9.70485 13.7596V7.64404H6.28711V19.3657H21.2594V13.7596Z"
        stroke={color}
        stroke-width="1.5"
      />
      <path
        d="M4.59961 22.5397L22.9463 4.5752"
        stroke={color}
        stroke-width="1.5"
      />
      <path
        d="M6.59473 6.07352C6.59473 6.07352 7.63855 3.84665 11.7096 3.67267C15.7806 3.49869 14.0409 0.784668 14.0409 0.784668"
        stroke={color}
      />
      <path
        d="M9.13477 6.28231C9.13477 6.28231 9.76111 5.20364 12.1968 5.27325"
        stroke={color}
      />
      <rect x="16.0635" y="14" width="3" height="3" fill={color} />
      <rect x="12.0635" y="14" width="3" height="3" fill={color} />
    </svg>
  )
}
