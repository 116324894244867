import { DownOutlined, UpOutlined } from '@ant-design/icons'
import type { TableProps } from 'antd'
import {
  Checkbox,
  Empty,
  Pagination,
  Table,
  Tooltip,
  message,
  notification,
} from 'antd'
import { t } from 'i18next'
import moment from 'moment'
import React, { Key, useCallback, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import { RootState } from '../../../app/store'
import STOCK_ICON from '../../../assets/icons/Stock icone.svg'
import ConditionnementRender from '../../../components/Common/DataType/ConditionnementType'
import DatetimeUserRender from '../../../components/Common/DataType/DatetimeUserRender'
import EmplacementRender from '../../../components/Common/DataType/EmplacementType'
import MovementRenderStock from '../../../components/Common/DataType/MouveMentStock'
import QteRender from '../../../components/Common/DataType/QteType'
import SelectRender from '../../../components/Common/DataType/SelectType'
import SortColumn, {
  getDefaultSorting,
} from '../../../components/Common/SortColumn'
import BLStock from '../../../components/Common/Stock/BLStock'
import EtatStock from '../../../components/Common/Stock/EtatStock'
import LotTooltip from '../../../components/Common/Stock/LotTooltip'
import ProduitStockTooltip from '../../../components/Common/Stock/ProduitStockTooltip'
import DatetimeUserTooltip from '../../../components/Common/Tooltip/DatetimeUserTooltip'
import MovementHeaderTooltip from '../../../components/Common/Tooltip/MovementHeaderTooltip'
import StatutTitleTooltip from '../../../components/Common/Tooltip/StatutHeaderTooltip'
import { default as Define, default as define } from '../../../constants/define'
import { Page, PagingEnum } from '../../../enum'
import { StatusStock } from '../../../enum/StatusStock'
import { useRenderDataByInterval } from '../../../hook/useRenderDataByInterval'
import useScroll from '../../../hook/useScroll'
import emplacementApi from '../../../http/emplacementApi'
import { StockDataType } from '../../../models'
import {
  clearList,
  getAllStock,
  getTotalStock,
} from '../../../redux/reducers/stockSlice'
import {
  convertRemToPixels,
  getCurrentFiltersText,
  renderDigitsByNumber,
} from '../../../utils'
import { makeTableValue } from '../../../utils/stock/makeTableValue'
import NewActionRow from '../components/ActionRow/NewActionRow'
import ResearchBar from '../components/ResearchBar/ResearchBar'
import { ModalName } from '../modal/customModalSlice'
import StatusTypeStock from './component/StatusTypeStock'
import './new-stock.scss'

const SelectBox = ({
  row,
  selectedRowKeys,
  setSelectedRowKeys,
  pageSize,
  loadMoreDetailLine,
}: {
  row: StockDataType
  selectedRowKeys: React.Key[]
  setSelectedRowKeys: (val: React.Key[]) => void
  pageSize: number
  loadMoreDetailLine: (val: string, amount: number) => void
}) => {
  const [ref, inView] = useInView()
  const [isFirstTimeView, setFirstTimeView] = useState(false)

  useEffect(() => {
    if (
      row.parentId &&
      inView &&
      !isFirstTimeView &&
      row.siblingAmount &&
      (row.index ===
        (row.siblingAmount > 10
          ? row.siblingAmount - 10
          : row.siblingAmount - 1) ||
        row.siblingAmount === 1)
    ) {
      // Perform actions when the component is in view
      setFirstTimeView(true)
      loadMoreDetailLine(row.parentId, row.siblingAmount || 0)
    }
  }, [inView, isFirstTimeView, row.siblingAmount])

  return (
    <div className="w-full flex justify-center" ref={ref}>
      <SelectRender
        isMainLine={true}
        isCheck={selectedRowKeys.includes(row.key)}
        onChangeChecked={(e) => {
          if (e.target.checked) {
            setSelectedRowKeys([...selectedRowKeys, row.key])
          } else {
            setSelectedRowKeys(
              [...selectedRowKeys].filter((i) => i !== row.key)
            )
          }
        }}
        screenColor={!!row.children ? 'gray' : 'grayer'}
      />
    </div>
  )
}

function isBlockOpenCDNModal(row: StockDataType) {
  return (
    row.isPalletePicking ||
    row.conditionnementLevel === Define.MAX_CDN_LEVEL - 1 ||
    row.status === StatusStock.SORTI
  )
}

const NewStock = () => {
  const [messageApi, contextHolder] = message.useMessage()
  const [isShowLess, setIsShowLess] = useState<boolean>(false)

  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([])
  const { choosingClient, choosingCompany, choosingWarehouse } = useSelector(
    (state: RootState) => state.account
  )
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [isCollapsible, setIsCollapsible] = useState<boolean>(false)

  const [searchParams] = useSearchParams()
  const pageIndex = searchParams.get('page-index') || 1
  const pageSize = searchParams.get('page-size') || 25
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    currentListStock: { stockList, loading, metadata },
    totalListStock,
  } = useSelector((state: RootState) => state.stock)
  const [rowHover, setRowHover] = useState<React.Key>()

  const openEmplacementPopup = async (row: StockDataType) => {
    try {
      await emplacementApi.getEmplacementByStockId(row.key.toString())
      navigate(`emplacement?stock-id=${row.key}`)
    } catch (e) {
      notification.open({
        message: t('notOpenPopup'),
        placement: 'topRight',
        duration: 3,
      })
    }
  }
  const columns: TableProps<StockDataType>['columns'] = [
    {
      title: <div className="text-[#808080]">Détail</div>,
      dataIndex: 'detail',
      key: 'detail',
      width: 80,
      fixed: 'left',
    },
    {
      title: () => (
        <Checkbox
          onChange={(e) => {
            if (e.target.checked) {
              let fullArray: Array<Key> = []
              data.forEach((item) => {
                fullArray.push(item.key)
                item.children?.map((i) => fullArray.push(i.key))
              })
              // @ts-ignore
              setSelectedRowKeys(fullArray)
            } else {
              setSelectedRowKeys([])
            }
          }}
          className="checkbox-gray flex justify-center"
        />
      ),
      dataIndex: 'select',
      key: 'select',
      width: 80,
      render: (_, row) => {
        return (
          <SelectBox
            row={row}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            pageSize={+pageSize}
            loadMoreDetailLine={loadMoreDetailLine}
          />
        )
      },
      fixed: 'left',
    },
    {
      title: () => (
        <Tooltip
          title={<MovementHeaderTooltip />}
          placement="bottom"
          color={'white'}
          overlayClassName="movement-header-tooltip"
          mouseEnterDelay={0.5}
          mouseLeaveDelay={0.2}
          overlayInnerStyle={{
            position: 'absolute',
            top: 0,
            borderRadius: 0,
            height: 'auto',
            left: -105,
          }}
        >
          Mouvement
        </Tooltip>
      ),
      dataIndex: 'mouvement',
      key: 'mouvement',
      width: 280,
      className: 'first-column',
      render: (_, row) => {
        return (
          <MovementRenderStock
            countMouvement={row.countMouvement}
            movement={row.mouvement}
            type={row.type}
            countType={row.countType}
            isChildren={!!row.children}
          />
        )
      },
    },
    {
      title: 'Etat',
      dataIndex: 'etat',
      key: 'etat',
      width: 80,
      render: (_, row) => {
        return (
          <EtatStock
            type={row.etat}
            isMainLine={row.children && row.children.length > 0}
            isHover={row.key === rowHover}
            countEtat={row.countEtat}
          />
        )
      },
    },
    {
      title: 'Produit',
      dataIndex: 'produit',
      key: 'produit',
      width: 200,
      defaultSortOrder: getDefaultSorting('reference_number', 'ascend'),
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: true,
      render: (_, row) => {
        return (
          <>
            {row.produit ? (
              <ProduitStockTooltip
                isMainLine={row.children && row.children.length > 0}
                produit={row.produit}
                libelle={row.libelle}
              />
            ) : (
              <>-</>
            )}
          </>
        )
      },
    },
    {
      title: 'Conditionnement',
      dataIndex: 'conditionnement',
      key: 'conditionnement',
      width: 170,
      render: (_, row) => {
        return (
          <ConditionnementRender
            type={row.conditionnement || ''}
            countCdn={row.countCdn}
            isMainLine={row.children && row.children.length > 0}
            niveau={row.niveau}
            isHover={row.key === rowHover}
            canOpenPopup={!isBlockOpenCDNModal(row)}
            stock={row}
          />
        )
      },
    },
    {
      title: 'Qte',
      dataIndex: 'qte',
      key: 'qte',
      width: 100,
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: (a, b) => a.qte - b.qte,
      render: (_, row) => {
        return (
          <QteRender
            data={row.qte}
            volume={row.volume_qte}
            poids={row.poids_qte}
          />
        )
      },
    },
    {
      title: 'Sous-Qte',
      dataIndex: 'sousqte',
      key: 'sousqte',
      width: 140,
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: (a, b) => a.sousqte - b.sousqte,
      render: (_, row) => {
        return (
          <QteRender
            notShowTooltip={
              row.isPalletePicking && row.niveau === define.MAX_CDN_LEVEL - 1
            }
            data={row.sousqte}
            volume={row.volume_sousqte}
            poids={row.poids_sousqte}
          />
        )
      },
    },
    {
      title: 'Total pièce',
      dataIndex: 'totalPiece',
      key: 'totalPiece',
      width: 200,
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: (a, b) => (a.totalPiece || 0) - (b.totalPiece || 0),
      render: (_, row) => {
        return <div className="hover-text">{row.totalPiece || '-'}</div>
      },
    },
    {
      title: 'Palette',
      dataIndex: 'palette',
      key: 'palette',
      width: 210,
      render: (_, row) => {
        return (
          <>
            {row.palette ? (
              isBlockOpenCDNModal(row) ? (
                <div className="text-secondary font-semibold hover-text cursor-pointer">
                  {row.palette}
                </div>
              ) : (
                <Link
                  to={`conditionnement?sscc_stock=${row.palette}&lot_stock=${
                    row.lot
                  }&ref_id_stock=${row.reference_id}&stock_id=${
                    row?.id
                  }&page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`}
                  className="text-secondary font-semibold hover-text"
                >
                  {row.palette}
                </Link>
              )
            ) : (
              <>-</>
            )}
          </>
        )
      },
    },
    {
      title: 'DLUO',
      dataIndex: 'dluo',
      key: 'dluo',
      width: 120,
    },
    {
      title: () => <StatutTitleTooltip />,
      dataIndex: 'statut',
      key: 'statut',
      width: 80,
      render: (_, row) => {
        return (
          <StatusTypeStock
            isHover={row.key === rowHover}
            stock={row}
            isMainLine={row.children && row.children.length > 0}
          />
        )
      },
    },
    {
      title: 'Emplacement',
      dataIndex: 'emplacement',
      key: 'emplacement',
      width: 150,
      render: (_, row) =>
        row.emplacement ? (
          <div onClick={() => openEmplacementPopup(row)}>
            <EmplacementRender
              previous={row.previous_location}
              current={row.current_location}
              type={row.emplacement}
              isHover={row.key === rowHover}
            />
          </div>
        ) : (
          <div>-</div>
        ),
    },
    {
      title: 'BL',
      dataIndex: 'bl',
      key: 'bl',
      width: 110,

      render: (_, row) => {
        return (
          <>
            {row.bl ? (
              <div className="text-secondary font-semibold hover-text">
                <BLStock attenduBl={row.bl} commandeBl={row.commande_bl} />
              </div>
            ) : (
              <div>-</div>
            )}
          </>
        )
      },
    },
    {
      title: 'Chrono',
      dataIndex: 'chrono',
      key: 'chrono',
      width: 120,
      defaultSortOrder: getDefaultSorting('chrono'),
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: true,
      render: (_, row) => {
        const isChild = Boolean(row.parentId)
        if (!isChild) {
          if (row.chrono)
            return (
              <Link
                className="hover-text text-[#808080]"
                to={`/gestion-de-stock/stock/chrono/reference/${
                  row.id
                }?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`}
              >
                {row.chrono}
              </Link>
            )

          return <span className="hover-text text-[#808080]">-</span>
        } else if (row.chrono) {
          return (
            <Link
              className="hover-text text-[#808080]"
              to={`/gestion-de-stock/stock/chrono/sscc/${
                row.sscc
              }?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`}
            >
              {row.chrono}
            </Link>
          )
        }
        return <span className="hover-text text-[#808080]">-</span>
      },
    },
    {
      title: 'Entrée',
      dataIndex: 'entree',
      key: 'entree',
      width: 170,
      render: (_, row) => {
        return (
          <>
            {row.entre_time ? (
              <div className="text-secondary font-semibold hover-text">
                {row.entre_time}
              </div>
            ) : (
              <div>-</div>
            )}
          </>
        )
      },
    },
    {
      title: 'Lot',
      dataIndex: 'lot',
      key: 'lot',
      width: 210,
      render: (_, row) => {
        return (
          <>
            {row.listLot && row.listLot.length > 1 ? (
              <>
                <LotTooltip listLot={row.listLot} />
              </>
            ) : (
              <>
                {row.listLot?.[0] ? (
                  <Tooltip title={row.lot}>
                    <div className="text-secondary font-semibold hover-text">
                      {renderDigitsByNumber(row.listLot?.[0], 11, true)}
                    </div>
                  </Tooltip>
                ) : (
                  <div>-</div>
                )}
              </>
            )}
          </>
        )
      },
    },
    {
      title: 'Fabrication',
      dataIndex: 'fabrication',
      key: 'fabrication',
      width: 170,
      render: (_, row) => {
        return (
          <>
            {row.fabrication ? (
              <div className="text-secondary font-semibold hover-text">
                {row.fabrication}
              </div>
            ) : (
              <div>-</div>
            )}
          </>
        )
      },
    },
    {
      title: 'Libération',
      dataIndex: 'liberation',
      key: 'liberation',
      width: 120,
      render: (_, row) => {
        return <DatetimeUserRender datetime={row.liberation} />
      },
    },
    {
      title: 'Création',
      dataIndex: 'creation',
      key: 'creation',
      width: 180,
      defaultSortOrder: getDefaultSorting('created_at'),
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: true,
      render: (_, row) => {
        if (!row.create_at) return '-'
        const createdAt = moment(row.create_at * 1000).format(t('time-format'))
        return (
          <div>
            <Tooltip
              title={
                <DatetimeUserTooltip
                  user={row.created_by}
                  datetime={createdAt}
                />
              }
              color="white"
            >
              {createdAt}
            </Tooltip>
          </div>
        )
      },
    },
    {
      title: 'Modification',
      dataIndex: 'modification',
      key: 'modification',
      width: 180,
      defaultSortOrder: getDefaultSorting('updated_at'),
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: true,
      render: (_, row) => {
        if (!row.update_at) return '-'

        const modifyAt = moment(Number(row.update_at) * 1000).format(
          t('time-format')
        )
        return (
          <div>
            <Tooltip
              title={
                <DatetimeUserTooltip
                  user={row.modification_by}
                  datetime={modifyAt}
                />
              }
              color="white"
            >
              {modifyAt}
            </Tooltip>
          </div>
        )
      },
    },
    {
      title: 'Validation',
      dataIndex: 'validation',
      key: 'validation',
      width: 180,
      render: (_, row) => {
        if (!row.validation_at) return '-'

        const validateAt = row.validation_at
        return (
          <div>
            <Tooltip
              title={
                <DatetimeUserTooltip
                  user={row.validation_by}
                  datetime={validateAt}
                />
              }
              color="white"
            >
              {validateAt}
            </Tooltip>
          </div>
        )
      },
    },
  ]

  const [fetchedStockParents, setFetchedStockParents] = useState<
    Array<{
      stockParentId: string
      currentAmount: number
    }>
  >([])

  const loadMoreDetailLine = (stockParentId: string, currentAmount: number) => {
    // if (
    //   !fetchedStockParents?.find(
    //     (condition) =>
    //       condition.stockParentId === stockParentId &&
    //       condition.currentAmount === currentAmount
    //   )
    // ) {
    //   loadMoreStockDetail(
    //     stockParentId,
    //     Number(pageSize) || 25,
    //     currentAmount
    //   ).then((res) => {
    //     const newItems = res.data.entry
    //     const meta = res.data.metadata
    //     if (newItems?.length) {
    //       dispatch(addDetailStock({ newItems, stockParentId, meta }))
    //     }
    //     setFetchedStockParents((current) => [
    //       ...current,
    //       { stockParentId, currentAmount },
    //     ])
    //   })
    // }
  }

  const searchStock = useCallback(
    (values: any) => {
      const searchParams = new URLSearchParams(values)
      const queryString = searchParams.toString()
      dispatch(clearList())
      navigate(
        `/gestion-de-stock/stock?page-index=1&page-size=${pageSize}&${queryString}`
      )
      setLoadMoreAll(false)
      setExpandedRowKeys([])
      setSelectedRowKeys([])
      setFetchedStockParents([])
      if (Number(pageIndex) === 1) {
        dispatch(getAllStock({ isRealTime: false }))
      }
      dispatch(getTotalStock())
    },
    [dispatch, navigate, pageIndex, pageSize]
  )

  useRenderDataByInterval({
    page: Page.STOCK,
    intervalTime: Define.ONE_MINUTE,
    dispatch,
    pageIndex,
    pageSize,
    navigate,
  })

  function loadMoreAllStock() {
    stockList?.forEach((stock) => {
      loadMoreDetailLine(stock.id, stock?.stock?.length || 0)
    })
  }

  const [isLoadMoreAll, setLoadMoreAll] = useState(false)

  useEffect(() => {
    if (
      fetchedStockParents.length === 0 &&
      stockList?.length > 0 &&
      !isLoadMoreAll
    ) {
      setLoadMoreAll(true)
      loadMoreAllStock()
    }
  }, [fetchedStockParents.length, stockList, isLoadMoreAll])

  const mapFieldByTableCol = {
    creation: 'created_at',
    modification: 'updated_at',
    produit: 'reference_number',
    chrono: 'chrono',
  }

  // handle sort by column
  const handleTableChange = async (p: any, f: any, sorter: any) => {
    // @ts-ignore
    const fieldSort = mapFieldByTableCol[sorter.field]
    if (fieldSort) {
      navigate(
        `/gestion-de-stock/stock?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText(
          ['sort', 'direction']
        )}&sort=${fieldSort}&direction=${sorter.order}`
      )
      dispatch(getAllStock({ isRealTime: false }))
    }
  }

  const onOpenModal = () => {
    navigate(
      `/gestion-de-stock/stock/stock-create?page-index=${pageIndex}&page-size=${pageSize}`
    )
  }

  const rowClassName = (record: any, index: number) => {
    if (!!record.children) {
      if (index % 2 === 0) return 'table-row-main-even'
      else return 'table-row-main-odd'
    } else return 'table-row-child'
  }

  const isChooseWarehouse =
    choosingClient && choosingWarehouse && choosingCompany

  const data = isChooseWarehouse ? makeTableValue(stockList) : []

  const height =
    window.innerHeight - 240 - convertRemToPixels(isCollapsible ? 19 : 4)

  useScroll(data)

  return (
    <>
      {contextHolder}
      <div className="new-stock">
        <ResearchBar
          screen={ModalName.STOCK}
          onSearch={searchStock}
          listFilters={[
            'BL',
            'Libelle',
            'Fournisseur',
            'StockType',
            'StockMouvement',
            'StockEtat',
            'Chrono',
            'Debut',
            'Fin',
            'Status',
            'Palette',
            'Lot',
            'Cdn',
            'Zone',
            'Allee',
            'Travee',
            'Niveau',
            'Type Cdn',
          ]}
          requiredToAdd={['client']}
          requiredToFilters={['client', 'company', 'warehouse']}
          isCollapsible={isCollapsible}
          setIsCollapsible={setIsCollapsible}
          searchBarHeight={12}
          totalIn={isChooseWarehouse ? metadata?.total : 0}
          total={isChooseWarehouse ? totalListStock?.number : 0}
        />
        <div className="flex ml-10 justify-between">
          <div className="flex items-center">
            <img src={STOCK_ICON} alt="stock-icon" className="mr-3" />
            <div className="page-title-heading-1">STOCK</div>
          </div>
          <NewActionRow
            isDisplayActionDropdown={selectedRowKeys.length >= 1}
            onClickPlus={onOpenModal}
            isDisableAdd={!choosingClient}
            isDisableActionButton={
              !choosingClient && !choosingCompany && !choosingWarehouse
            }
          />
        </div>

        <div className="flex justify-center new-stock">
          <Table
            virtual
            onChange={handleTableChange}
            showSorterTooltip={false}
            className="attendu-cmd-stock  table-wrapper overflow-y-auto relative text-[#EBEBEB]"
            columns={columns}
            pagination={false}
            scroll={{ x: 'scroll', y: height }}
            indentSize={0}
            loading={loading}
            dataSource={loading ? [] : data || []}
            rowClassName={rowClassName}
            locale={{
              emptyText: (
                <span>
                  <Empty
                    description={''}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                  <p className="text-center">
                    {choosingClient && choosingWarehouse && choosingCompany
                      ? t('noStockFound')
                      : t('notEnoughCondition')}
                  </p>
                </span>
              ),
            }}
            expandable={{ expandedRowKeys }}
            expandIcon={({ expanded, onExpand, record }) =>
              // only show dropdown icon if only has children
              record.children !== undefined && record.children?.length > 0 ? (
                expanded ? (
                  <UpOutlined
                    onClick={() => {
                      setExpandedRowKeys(
                        expandedRowKeys.filter((item) => item !== record.key)
                      )
                    }}
                  />
                ) : (
                  <DownOutlined
                    onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                      if (e.shiftKey || e.ctrlKey) {
                        setExpandedRowKeys([...expandedRowKeys, record.key])
                        if (expandedRowKeys.length >= 10) {
                          messageApi.warning(
                            'Attention : l’affichage de plus de Détails peut limiter vos performances'
                          )
                        }
                      } else {
                        setExpandedRowKeys([record.key])
                      }
                    }}
                  />
                )
              ) : (
                <></>
              )
            }
            onRow={(record, rowIndex) => {
              return {
                onMouseEnter: (e) => {
                  setRowHover(record.key)
                },
                onMouseLeave: (e) => {
                  setRowHover(undefined)
                },
              }
            }}
          />
        </div>
        {choosingClient &&
          choosingWarehouse &&
          choosingCompany &&
          stockList &&
          stockList.length !== 0 && (
            <Pagination
              className="custom-pagination stock-pagination mt-4"
              locale={{ items_per_page: '' }}
              total={metadata?.total || 0}
              defaultPageSize={PagingEnum.DEFAULT_PAGE_SIZE}
              showLessItems={isShowLess}
              pageSizeOptions={[
                PagingEnum.DEFAULT_PAGE_SIZE,
                PagingEnum.PAGE_SIZE_1,
                PagingEnum.PAGE_SIZE_2,
              ]}
              pageSize={Number(pageSize)}
              current={Number(pageIndex)}
              showSizeChanger
              showTotal={(total, range) =>
                `${range[0] >= 0 ? range[0] : 0}-${
                  range[1] >= 0 ? range[1] : 0
                } sur ${total}`
              }
              onChange={(page, size) => {
                navigate(
                  `/gestion-de-stock/stock?page-index=${page}&page-size=${size}${getCurrentFiltersText()}`
                )
                setIsShowLess(() => {
                  return page > 4
                })
              }}
            />
          )}
      </div>
      <Outlet />
    </>
  )
}

export default NewStock
