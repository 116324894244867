interface IconInterface {
  color: string
  opacity?: number
  fill?: string
  onMouseLeave: () => void
  onMouseEnter: () => void
}

export default function EtatTmp(props: IconInterface) {
  const { color, opacity, fill, onMouseEnter, onMouseLeave } = props
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 27 27"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      opacity={opacity}
      display="block"
    >
      <circle cx="13.5" cy="13.5" r="12.75" stroke={color} stroke-width="1.5" />
      <mask id="path-2-inside-1_22486_22653" fill="white">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.5 10V6.5C15.5 5.39543 14.6046 4.5 13.5 4.5C12.3954 4.5 11.5 5.39543 11.5 6.5V10H10V6.5C10 4.567 11.567 3 13.5 3C15.433 3 17 4.567 17 6.5V10H15.5Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5 10V6.5C15.5 5.39543 14.6046 4.5 13.5 4.5C12.3954 4.5 11.5 5.39543 11.5 6.5V10H10V6.5C10 4.567 11.567 3 13.5 3C15.433 3 17 4.567 17 6.5V10H15.5Z"
        fill="white"
      />
      <path
        d="M15.5 10H14V11.5H15.5V10ZM11.5 10V11.5H13V10H11.5ZM10 10H8.5V11.5H10V10ZM17 10V11.5H18.5V10H17ZM17 10V6.5H14V10H17ZM17 6.5C17 4.567 15.433 3 13.5 3V6C13.7761 6 14 6.22386 14 6.5H17ZM13.5 3C11.567 3 10 4.567 10 6.5H13C13 6.22386 13.2239 6 13.5 6V3ZM10 6.5V10H13V6.5H10ZM10 11.5H11.5V8.5H10V11.5ZM8.5 6.5V10H11.5V6.5H8.5ZM13.5 1.5C10.7386 1.5 8.5 3.73858 8.5 6.5H11.5C11.5 5.39543 12.3954 4.5 13.5 4.5V1.5ZM18.5 6.5C18.5 3.73858 16.2614 1.5 13.5 1.5V4.5C14.6046 4.5 15.5 5.39543 15.5 6.5H18.5ZM18.5 10V6.5H15.5V10H18.5ZM15.5 11.5H17V8.5H15.5V11.5Z"
        fill={color}
        mask="url(#path-2-inside-1_22486_22653)"
      />
      <mask id="path-4-inside-2_22486_22653" fill={fill}>
        <path d="M6 16C6 12.134 9.13401 9 13 9H14C17.866 9 21 12.134 21 16C21 19.866 17.866 23 14 23H13C9.13401 23 6 19.866 6 16Z" />
      </mask>
      <path
        d="M6 16C6 12.134 9.13401 9 13 9H14C17.866 9 21 12.134 21 16C21 19.866 17.866 23 14 23H13C9.13401 23 6 19.866 6 16Z"
        fill={color}
      />
      <path
        d="M13 10.5H14V7.5H13V10.5ZM14 21.5H13V24.5H14V21.5ZM13 21.5C9.96243 21.5 7.5 19.0376 7.5 16H4.5C4.5 20.6944 8.30558 24.5 13 24.5V21.5ZM19.5 16C19.5 19.0376 17.0376 21.5 14 21.5V24.5C18.6944 24.5 22.5 20.6944 22.5 16H19.5ZM14 10.5C17.0376 10.5 19.5 12.9624 19.5 16H22.5C22.5 11.3056 18.6944 7.5 14 7.5V10.5ZM13 7.5C8.30558 7.5 4.5 11.3056 4.5 16H7.5C7.5 12.9624 9.96243 10.5 13 10.5V7.5Z"
        fill={color}
        mask="url(#path-4-inside-2_22486_22653)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.84591 12.7971L12.6047 16.0766L9.84556 19.3565C9.29838 20.007 9.76079 21.0003 10.6108 21.0003H16.2553C17.1053 21.0003 17.5677 20.007 17.0205 19.3565L14.2617 16.077L17.0209 12.7971C17.5681 12.1466 17.1056 11.1533 16.2556 11.1533H10.6112C9.76114 11.1533 9.29872 12.1466 9.84591 12.7971ZM13.0632 15.3393C13.2616 15.5584 13.6058 15.5584 13.8043 15.3393L15.3529 13.6298C15.6441 13.3085 15.416 12.7942 14.9824 12.7942L11.8851 12.7942C11.4514 12.7942 11.2234 13.3085 11.5145 13.6298L13.0632 15.3393ZM13 17.5C13 17.2239 13.2239 17 13.5 17C13.7761 17 14 17.2239 14 17.5C14 17.7761 13.7761 18 13.5 18C13.2239 18 13 17.7761 13 17.5ZM10.9786 19.5351L11.2241 19.2222C11.3189 19.1013 11.464 19.0308 11.6175 19.0308H15.2515C15.405 19.0308 15.55 19.1013 15.6448 19.2222L15.8904 19.5351C16.1478 19.8632 15.914 20.3438 15.497 20.3438H11.372C10.9549 20.3438 10.7212 19.8632 10.9786 19.5351Z"
        fill={fill}
      />
    </svg>
  )
}
