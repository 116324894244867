interface IconInterface {
  color: string
  opacity?: number
  fill?: string
  onMouseLeave: () => void
  onMouseEnter: () => void
}

export default function EtatReserve(props: IconInterface) {
  const { color, opacity, fill, onMouseEnter, onMouseLeave } = props
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 27 27"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      opacity={opacity}
      display="block"
    >
      <circle cx="13.5" cy="13.5" r="12.75" stroke={color} stroke-width="1.5" />
      <mask id="path-2-inside-1_11997_16956" fill={color}>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.5 10.5V6.5C15.5 5.39543 14.6046 4.5 13.5 4.5C12.3954 4.5 11.5 5.39543 11.5 6.5V10.5C11.5 11.6046 12.3954 12.5 13.5 12.5C14.6046 12.5 15.5 11.6046 15.5 10.5ZM13.5 3C11.567 3 10 4.567 10 6.5V10.5C10 12.433 11.567 14 13.5 14C15.433 14 17 12.433 17 10.5V6.5C17 4.567 15.433 3 13.5 3Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5 10.5V6.5C15.5 5.39543 14.6046 4.5 13.5 4.5C12.3954 4.5 11.5 5.39543 11.5 6.5V10.5C11.5 11.6046 12.3954 12.5 13.5 12.5C14.6046 12.5 15.5 11.6046 15.5 10.5ZM13.5 3C11.567 3 10 4.567 10 6.5V10.5C10 12.433 11.567 14 13.5 14C15.433 14 17 12.433 17 10.5V6.5C17 4.567 15.433 3 13.5 3Z"
        fill={color}
      />
      <path
        d="M14 6.5V10.5H17V6.5H14ZM13.5 6C13.7761 6 14 6.22386 14 6.5H17C17 4.567 15.433 3 13.5 3V6ZM13 6.5C13 6.22386 13.2239 6 13.5 6V3C11.567 3 10 4.567 10 6.5H13ZM13 10.5V6.5H10V10.5H13ZM13.5 11C13.2239 11 13 10.7761 13 10.5H10C10 12.433 11.567 14 13.5 14V11ZM14 10.5C14 10.7761 13.7761 11 13.5 11V14C15.433 14 17 12.433 17 10.5H14ZM11.5 6.5C11.5 5.39543 12.3954 4.5 13.5 4.5V1.5C10.7386 1.5 8.5 3.73858 8.5 6.5H11.5ZM11.5 10.5V6.5H8.5V10.5H11.5ZM13.5 12.5C12.3954 12.5 11.5 11.6046 11.5 10.5H8.5C8.5 13.2614 10.7386 15.5 13.5 15.5V12.5ZM15.5 10.5C15.5 11.6046 14.6046 12.5 13.5 12.5V15.5C16.2614 15.5 18.5 13.2614 18.5 10.5H15.5ZM15.5 6.5V10.5H18.5V6.5H15.5ZM13.5 4.5C14.6046 4.5 15.5 5.39543 15.5 6.5H18.5C18.5 3.73858 16.2614 1.5 13.5 1.5V4.5Z"
        fill={color}
        mask="url(#path-2-inside-1_11997_16956)"
      />
      <rect
        x="6.75"
        y="9.75"
        width="13.5"
        height="12.5"
        rx="6.25"
        fill={color}
        stroke={color}
        stroke-width="1.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.684 16.0855C15.3291 15.7031 15.7616 14.9998 15.7616 14.1955C15.7616 12.983 14.7787 12 13.5661 12C12.3536 12 11.3706 12.983 11.3706 14.1955C11.3706 14.9998 11.8031 15.7031 12.4482 16.0855L11.59 18.6602C11.4821 18.9839 11.723 19.3183 12.0643 19.3183H15.0679C15.4092 19.3183 15.6502 18.9839 15.5422 18.6602L14.684 16.0855Z"
        fill={fill}
      />
    </svg>
  )
}
