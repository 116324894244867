import { Tooltip } from 'antd'
import { useState } from 'react'
import EtatDisponible from '../../../assets/icons/EtatDisponible'
import EtatReserve from '../../../assets/icons/EtatReserve'
import EtatSorti from '../../../assets/icons/EtatSorti'
import EtatTooltip from '../Tooltip/EtatTooltip'
import EtatTmp from '../../../assets/icons/EtatTmp'
import { EtaStock } from '../../../enum/StatusStock'

interface EtatRenderProps {
  type?: EtaStock
  isMainLine?: boolean
  isHover?: boolean
  countEtat?: number
}

const EtatStock = (props: EtatRenderProps) => {
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false)

  const renderEtat = (type?: EtaStock) => {
    switch (type) {
      case EtaStock.CORRESPOND:
        return (
          <Tooltip
            title={<EtatTooltip etat="Réservé" />}
            color="#FFFFFF"
            overlayInnerStyle={{ borderRadius: 0 }}
            open={isShowTooltip}
            mouseEnterDelay={0.3}
          >
            <EtatReserve
              color={props.isHover ? '#FFF' : '#B7B7B7'}
              opacity={props.isMainLine ? 1 : 0.5}
              fill={props.isHover ? '#8B9FC7' : '#FFF'}
              onMouseLeave={() => {
                setIsShowTooltip(false)
              }}
              onMouseEnter={() => {
                setIsShowTooltip(true)
              }}
            />
          </Tooltip>
        )
      case EtaStock.SORTI:
        return (
          <Tooltip
            title={<EtatTooltip etat="Sorti" />}
            color="#FFFFFF"
            overlayInnerStyle={{ borderRadius: 0 }}
            open={isShowTooltip}
            mouseEnterDelay={0.3}
          >
            <EtatSorti
              color={props.isHover ? '#FFF' : '#F25524'}
              opacity={props.isMainLine ? 1 : 0.5}
              fill={props.isHover ? '#8B9FC7' : 'none'}
              onMouseLeave={() => {
                setIsShowTooltip(false)
              }}
              onMouseEnter={() => {
                setIsShowTooltip(true)
              }}
            />
          </Tooltip>
        )
      case EtaStock.CREATE:
        return (
          <Tooltip
            title={<EtatTooltip etat="Disponible" />}
            color="#FFFFFF"
            overlayInnerStyle={{ borderRadius: 0 }}
            open={isShowTooltip}
            mouseEnterDelay={0.3}
          >
            <EtatDisponible
              color={props.isHover ? '#FFF' : '#01E37A'}
              opacity={props.isMainLine ? 1 : 0.5}
              fill={props.isHover ? '#8B9FC7' : 'none'}
              onMouseLeave={() => {
                setIsShowTooltip(false)
              }}
              onMouseEnter={() => {
                setIsShowTooltip(true)
              }}
            />
          </Tooltip>
        )
      case EtaStock.TMP:
        return (
          <Tooltip
            title={<EtatTooltip etat="Réservé tmp" />}
            color="#FFFFFF"
            overlayInnerStyle={{ borderRadius: 0 }}
            open={isShowTooltip}
            mouseEnterDelay={0.3}
          >
            <EtatTmp
              color={props.isHover ? '#FFF' : '#B7B7B7'}
              opacity={props.isMainLine ? 1 : 0.5}
              fill={props.isHover ? '#8B9FC7' : '#FFF'}
              onMouseLeave={() => {
                setIsShowTooltip(false)
              }}
              onMouseEnter={() => {
                setIsShowTooltip(true)
              }}
            />
          </Tooltip>
        )
      default:
        return <></>
    }
  }

  if (props.countEtat) {
    return (
      <div
        className="rounded-full  bg-[#C8E7F9] text-center mouvement-hover"
        style={{ height: 23, width: 23 }}
      >
        <div
          className="flex justify-center items-center"
          style={{ height: 23 }}
        >
          <div className="text-[#0068AC] mouvement-hover-text">
            {props.countEtat}
          </div>
        </div>
      </div>
    )
  } else {
    return renderEtat(props.type)
  }
}

export default EtatStock
